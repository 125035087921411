<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" userFilter="stockMovementFilters" :dateRanger="dateRanger" :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" :searchPlaceholder="searchPlaceholder" @setDates="setDates" :archiveMode="archiveMode" :archive="archive" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
    b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols='12' style='padding: 0 !important; height: 100% !important')
      ul.d-flex
        li
          a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
            span Tout ({{ getCountDataByStatus("all") }})
        li
          a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'income' ? 'current' : ''" @click="tabActive = 'income'")
            span Entrées ({{ getCountDataByStatus("income") }})
        li
          a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'outcome' ? 'current' : ''" @click="tabActive = 'outcome'")
            span Sorties ({{ getCountDataByStatus("outcome") }})
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingStockMovementsList || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des mouvements de stock...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      name: "stockMovement",
      uid: "stockMovements",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      dateRanger: true,
      tvaModeButton: false,
      addButton: false,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher un mouvement de stock",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "date",
        field: "movementDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        format: "dd/MM/yyyy HH:mm",
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "storageLocationLabel",
        headerText: "Point de stockage",
        width: 200,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: false,
        visible: true,
      },
      {
        type: "string",
        field: "product.reference",
        headerText: "Référence",
        width: 200,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "string",
        field: "label",
        headerText: "Désignation",
        width: "auto",
        maxWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filter: { type: "Menu", operator: "contains" },
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
      },
      {
        type: "number",
        format: "formatNumber",
        field: "quantity",
        headerText: "Quantité",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        template: "quantityTemplate",
      },
      {
        type: "string",
        field: "sourceReference",
        headerText: "Source",
        width: 200,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  computed: {
    ...mapGetters(["isLoadingStockMovementsList", "userParametersList"]),
    dataOrigine() {
      return this.$store.getters[this.name + "sList"];
    },
    dataFiltered() {
      if (this.tabActive == "all") {
        return this.$store.getters[this.name + "sList"];
      } else if (this.tabActive == "income") {
        return this.$store.getters[this.name + "sList"].filter(
          (elem) => elem.direction === 0
        );
      } else if (this.tabActive == "outcome") {
        return this.$store.getters[this.name + "sList"].filter(
          (elem) => elem.direction === 1
        );
      }
    },
  },
  async activated() {
    let filter = this.userParametersList.find(
      (el) => el.key == "stockMovementFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions(["getStockMovements"]),
    formatCurrency,
    setColumnsByObject,
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getStockMovements({
          startTo: dateRange.startDate,
          endTo: dateRange.endDate,
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else if (status == "income") {
        return this.dataOrigine.filter((elem) => elem.direction == 0).length;
      } else if (status == "outcome") {
        return this.dataOrigine.filter((elem) => elem.direction == 1).length;
      }
    },
  },
};
</script>
